import React, { useContext, useEffect, useState } from "react";
import Logo from "../asset/Image/logo.png"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import LoadingBtn from "../component/LoadingBtn";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function Login() {
  const {isAuth,setIsAuth}=useContext(AuthContext)
  const {isAdmin,setIsAdmin}=useContext(AuthContext)
  const {setUser}=useContext(AuthContext)
  const [tab, setTab] = useState("signin");
  const [edited,setEdited]=useState({})
    const [dept,setDept]=useState([])
  const navigate=useNavigate()
  const [forgotPopup, setforgotPopup] = useState(false)
  const [loginform, Setloginform] = useState({ email: "", password: "" })
  const [Loading,setLoading]=useState(false)
  const [resetLoading,setResetLoading]=useState(false)
  const [email,setEmail]=useState("")

  const [newuser,setNewuser]=useState({
      username:"",
      password:"",
      email:"",
      department:"",
      branch:"",
      phone_number:"",
      first_name:"",
      last_name:""
  })
  const getCsrfToken = () => {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    return cookieValue || '';
};


const userInput=(e)=>{
    
  setNewuser(prev=>{
    return {
      ...prev,
      [e.target.name]:e.target.name==="department"? Number(e.target.value):e.target.value
    }
  })

  setEdited(prev=>{
    return {
      ...prev,
      [e.target.name]:e.target.value
    }
  })
  
}

const createUser=(e)=>{
  e.preventDefault()
  setLoading(true)
  let email=newuser.email.split("@")

  
  if(email[1].toLowerCase()!="awashwine.com"){toast.warning("Inavlid Email")}else{
    const getCsrfToken = () => {
      const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
      return cookieValue || '';
  };
  
  
  //
    axios.post(`${process.env.REACT_APP_URL}/api/create_user/`,
      newuser ,{
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
      },
    }
    ).then(res=>{
      setLoading(false)
      setNewuser({
        username:"",
        password:"",
        email:"",
        department:"",
        branch:"",
        phone_number:"",
        first_name:"",
        last_name:""
    })
      toast.success("User Created Successfuly")    
    }).catch(err=>{
      toast.error("Creating User Failed")
      setLoading(false)
      if(typeof(err?.response?.data)==="object"){
        
        let errors=Object.keys(err?.response?.data)
        errors.forEach(error => {
        if(error!=="non_field_errors"){
        return  toast.warning(`${error}: ${err?.response?.data[error][0]}`)
        }
        return toast.warning(`${err?.response?.data[error][0]}`)
      });
      }else{
        toast.warning(`Server Error Please Try Again Later`)
      }
      console.log(err);
      
    })
    //to here
  }
}
const deptOption=dept.map((el,index)=><option value={Number(el.id)} key={index}>{el.name}</option>)

useEffect(()=>{
  
      // Set cookies to expire
document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    //fetch department
    axios.get(`${process.env.REACT_APP_URL}/api/departments`,{
      headers:{
        Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
      }
    }).then(res=>{
      setDept(res.data)
      
    }).catch(err=>{
      console.log(err);
      
    })

// Clear session storage
sessionStorage.removeItem('isAuth');
sessionStorage.removeItem('isAdmin');
},[])

  const checkActivation=async (id)=>{
    await axios.get(`${process.env.REACT_APP_URL}/api/check_activation_status/${id}`,{headers: {
      'X-CSRFToken': getCsrfToken(),
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
  }}).then(res=>{
    console.log("success",res.data.activated);
    
    return res.data.activated
  }
  ).catch(err=>{
    console.log("activation",err);
    
  })
  }
  const submitLogin = (e) => {
    e.preventDefault()

    setLoading(true)

    axios.post(`${process.env.REACT_APP_URL}/api/token/`,loginform,{ withCredentials: true ,headers: {
      'X-CSRFToken': getCsrfToken(),
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
  }})
    .then(async (resp) => {
   

      await axios.get(`${process.env.REACT_APP_URL}/api/check_activation_status/${resp?.data?.user?.id}`,{headers: {
        'X-CSRFToken': getCsrfToken(),
        Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    }}).then(res=>{
      console.log("success",res.data.activated);
      // from here
      if(res.data.activated){
        document.cookie=`access_token=${resp.data.access}`
      document.cookie=`refresh_token=${resp.data.refresh}`
      setLoading(false)
      localStorage.setItem("user",resp?.data?.user?.username)
      setUser(resp?.data?.user?.username)
      if(resp.data?.user?.role==="admin"){
        setIsAdmin(true)
        sessionStorage.setItem("isAdmin",true) 
        
        navigate("/admin/dashboard")
      }else if(resp.data?.user?.role==="employee"){
        setIsAuth(true)
        sessionStorage.setItem("isAuth",true) 
        navigate("/dashboard")
      }
      }else{
        toast.error("Account is not activated")
        setLoading(false)        
        
      }
      


      //to here
    }
    ).catch(err=>{
      toast.error("Account is not activated")
        setLoading(false)  
      
    })
      
      // navigate("/dashboard")
    }).catch(err => {
      setLoading(false)
      if(typeof(err?.response?.data)==="object"){
        
        let errors=Object.keys(err?.response?.data)
        errors.forEach(error => {
        if(error!=="non_field_errors"){
        return  toast.warning(`${error}: ${err?.response?.data[error][0]}`)
        }
        return toast.warning(`${err?.response?.data[error][0]}`)
      });
      }else{
        toast.warning(`Server Error Please Try Again Later`)
      }
      
      
      console.log(err)
    })
  }
  const handleLoginchange = (e) => {
    Setloginform(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }


  const forgotPassword=async (e)=>{

    e.preventDefault()
    setResetLoading(true)
    await axios.post(`${process.env.REACT_APP_URL}/api/password_reset/`,{email},{
      headers:{
        Authorization:`API_KEY ${process.env.REACT_APP_API_KEY}`
      }
    }).then(res=>{
      setResetLoading(false)
      
    }).catch(err=>{
      setResetLoading(false)
      console.log(err);
      
    })
  }


  return (
    <div className="login-page">
      <ToastContainer/>
      {/* popup*/}
      <div className={`forgot-password_popup ${forgotPopup && "active"}`}>
        <div className="popup-pin" onClick={() => {
          setforgotPopup(false)
        }}></div>

        <div className="popup_header"><h2>Reset your Password</h2></div>

        <form className="reset-form" onSubmit={forgotPassword} onChange={(e)=>setEmail(e.target.value)}>
          <div className="form-ctrl">
            <label htmlFor="reset-email">Email Address <span>*</span></label>
            <input type="email" name="email" id="reset-email" />
          </div>
          <div className="forgot-signin sigup-btn-conatiner">
            {resetLoading?<LoadingBtn/>:<button className="btn-login">Send Reset Link</button>}
          </div>
        </form>
      </div>
      {/* popup*/}
      <div className="login-left">
        <div className="tab-container">
          <div className="header-container">
            <div className="logo"><img src={Logo} alt='Logo' width="45px" /></div>
            <div className="app-name"><h2>Awash Wine IT Help Desk</h2></div>
          </div>
          {/* signin container */}
          <div className={`login-container ${tab === "signin" && "active-signin"}`}>
            <form className="login-form" onSubmit={submitLogin} onChange={handleLoginchange}>
              <div className="login-header">
                <h1>Welcome Back !</h1>
              </div>
              <div className="form-ctrl">
                <label htmlFor="id">Email Address</label>
                <input type="text" name="email" id="id" />
              </div>
              <div className="form-ctrl">
                <label htmlFor="passwords">Password</label>
                <input type="password" name="password" id="passwords" />
              </div>
              <div className="forgot-signin">
                
                <p onClick={() => {
                  setforgotPopup(true)
                }}>Forgot Password ?</p>
                {Loading?<LoadingBtn/>:<button className="btn-login" >Sign In</button>}
                
              </div>
              <span className="tab" onClick={() => {
                setTab("signup")
              }}> Sign Up</span>
            </form>
          </div>
          {/* signin container */}


          {/* signup container */}
          <div className={`signup-container ${tab === "signup" && "active-signup"}`}>
            <form className="login-form" onChange={userInput} onSubmit={createUser}>
            <div className="form-ctrl">
            <label htmlFor="username">User Name <span>*</span></label>
            <input type="text" name="username" id="username" required value={newuser && newuser.username}/>
          </div>
          
          <div className="form-ctrl">
            <label htmlFor="password">Password <span>*</span></label>
            <input type="text" name="password" id="password" required  value={newuser && newuser.password}/>
          </div>
          <div className="form-ctrl">
            <label htmlFor="email">Email <span>*</span></label>
            <input type="email" name="email" id="email" required  value={newuser && newuser.email}/>
          </div>
          <div className="form-ctrl">
            <label htmlFor="dept">Department</label>
            <select name="department" id="dept" value={newuser && newuser.department===null?"":newuser.department}>
              <option value="">Select Department</option>
              {deptOption}
            </select>
          </div>
          <div className="form-ctrl">
            <label htmlFor="branch">Branch</label>
            <select name="branch" id="branch"  value={newuser && newuser.branch===null?"":newuser.branch}>
            <option value="">Select Your Branch</option>
              <option value="Farm">Farm</option>
              <option value="Kality">Kality</option>
              <option value="Lideta">Lideta</option>
              <option value="Mekanissa">Mekanissa</option>
            </select>
          </div>
          <div className="form-ctrl">
            <label htmlFor="phone_number">Phone_number <span>*</span></label>
            <input type="phone_number" name="phone_number" id="phone_number" required value={newuser && newuser.phone_number}/>
          </div>
          <div className="form-ctrl">
            <label htmlFor="first_name">First_name <span>*</span></label>
            <input type="first_name" name="first_name" id="first_name" required  value={newuser && newuser.first_name}/>
          </div>
          <div className="form-ctrl">
            <label htmlFor="last_name">Last_name <span>*</span></label>
            <input type="last_name" name="last_name" id="last_name" required  value={newuser && newuser.last_name}/>
          </div>
              <div className="forgot-signin sigup-btn-conatiner">
              {Loading?<LoadingBtn/>:<button className="btn-login">Sign Up</button>}
              </div>
            </form>
            <div className="signup">
              <p>
                Have an account?
              </p><span className="tab" onClick={() => {
                setTab("signin")
              }}> Sign In</span>
            </div>
          </div>
          {/* signup container */}
        </div>
      </div>
      <div className="login-right"></div>


    </div>
  );
}

export default Login;
