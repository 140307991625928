import React from 'react'
import { Link } from 'react-router-dom'
function PageNotFound() {
  return (
    <div className="admin-dashboard .activate-body">
    <div className='NotFound'>
      <h1>404</h1>
      <p>Page Not Found</p>
      <Link to="/">Back to Home Page</Link>
    </div>
    </div>
  )
}

export default PageNotFound 
