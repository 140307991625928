import axios from 'axios'
import React, { useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom'

function Activation() {
    const {encId}=useParams()
    const [agree,setAgree]=useState(false)
    const navigate=useNavigate()

    const getCsrfToken = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];
        return cookieValue || '';
    };

    const activate=()=>{

        console.log(encId);
        
        axios.post(`${process.env.REACT_APP_URL}/api/activate_account/${encId}/`,null,{
            headers: {
                'X-CSRFToken': getCsrfToken(),
                Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
              },
        }).then(res=>{
            console.log(res);
            toast.success(res.data.message)
            toast.success("will be redirected to login page soon")
            setTimeout(()=>{
                navigate("/")
            },2000)

            
        }).catch(err=>{
            console.log(err,"error");
            toast.error(err.response.data.message)
            toast.error("will be redirected to login page soon")
            setTimeout(()=>{
                navigate("/")
            },3000)
        })
    }

    const agreeTerm=(e)=>{
        console.log(e.target.checked);
        setAgree(e.target.checked)
    }
  return (
    <div className="admin-dashboard activate-body">
        <ToastContainer/>
      <div className="activate-container">
        <h2>IT Helpdesk Terms and Conditions</h2>

<h4>Purpose</h4>

<p>The IT Helpdesk is provided to assist users with technical issues and inquiries related to Awash Wines's IT systems and services.</p>
<h4>Scope of Services</h4>

<p>The IT Helpdesk offers support for hardware, software, network issues, and other IT-related problems.
Support is available during business hours.</p>
<h4>User Responsibilities.</h4>

<p>Users must provide accurate and complete information when requesting support.
Users should follow the instructions provided by the IT Helpdesk staff.
Users are responsible for maintaining the confidentiality of their account information.</p>
<h4>Response Time</h4>

<p>The IT Helpdesk aims to respond to all inquiries within 10min.
Response times may vary depending on the complexity of the issue.</p>
<h4>Confidentiality.</h4>

<p>All information shared with the IT Helpdesk will be treated as confidential.
The IT Helpdesk staff will not disclose any user information without prior consent, except as required by law.</p>
<h4>Limitations of Liability</h4>

<p>The IT Helpdesk is not responsible for any data loss or damage resulting from the support provided.
Users acknowledge that the IT Helpdesk services are provided on a best-effort basis.</p>
<h4>Changes to Terms and Conditions.</h4>

<p>Awash Wine reserves the right to modify these terms and conditions at any time.</p>

<h4>Acceptance of Terms</h4>

<p>By using the IT Helpdesk, users agree to abide by these terms and conditions.</p>

<div className="agree-terms">
        <form onChange={agreeTerm}>
            <div className="flex">
                <input type="checkbox" name="agree" checked={agree}/> <span>I agree to the Terms and Conditions</span>
            </div>
        </form>
        {agree?<button className='btn-login' onClick={activate}>Activate</button>:<button className='btn-login' disabled >Activate</button>}
        
</div>
      </div>
    </div>
  )
}

export default Activation
